<template>
  <div>
    <div class="container-main">
      <!-- 头部横栏 -->
      <a target="_blank" href="https://github.com/JumpAlang/Jusic-serve">Jusic-serve</a>
      <!--播放主页面-->
      <div class="demo-container">
        <mu-row gutter class="mu-gutter-top">
          <mu-col span="6" :style="{ height: (pageHeight * 0.8) +'px' }">
            <!--<div class="message-input-group">-->
            <div style="height: 100px">
              <div style="padding-top: 10px;display: inline-flex;">
                <mu-button color="#009688" class="btn-top-list" @click="open = true; reductionServer()">
                  <span>收藏</span>
                </mu-button>
                <mu-button color="#009688" class="btn-top-list" @click="openSearch = !openSearch">
                  <span>点歌</span>
                </mu-button>
                <mu-button color="#009688" class="btn-top-list" @click="openSearchGd = !openSearchGd">
                  <span>歌单</span>
                </mu-button>
                <mu-button color="#009688" class="btn-top-list" @click="searchTop">
                  <span>热歌榜</span>
                </mu-button>
                <!-- 音乐来源 -->
                <div class="top-choice-center">
                  <mu-radio :value="'wy'" v-model="sourceChat" color="primary" :label="'网易'" class="searchSource"/>
                  <mu-radio :value="'qq'" v-model="sourceChat" color="primary" :label="'QQ'" class="searchSource"/>
                </div>
              </div>
            </div>
            <!-- 播放列表 -->
            <mu-data-table
              :style="{ height: (pageHeight * 0.8 - 100) + 'px', overflow: 'auto' }"
              class="table-style"
              :selectable="false"
              :hover="false"
              :columns="columns"
              :data="pick"
            >
              <template slot-scope="scope">
                <td class="is-center">
                  <!-- 删除收藏 -->
                  <a @click="removeCollect(scope.row)"
                     v-if="favoriteMap[scope.row.id] !== null && favoriteMap[scope.row.id] !== undefined"
                     style="cursor: pointer"
                  >
                    <mu-icon value="favorite" size="20" color="red"></mu-icon>
                  </a>
                  <!-- 收藏 -->
                  <a v-else style="cursor: pointer" @click="collectMusic(scope.row)">
                    <mu-icon value="favorite" size="20" color="white"></mu-icon>
                  </a>
                </td>
                <!-- 列表序号 -->
                <td class="is-center">
                  {{ scope.$index + 1 }}
                </td>
                <td class="is-left">
                  <a @click="goodMusic(scope.row)"
                     v-if="scope.$index !== 0 && good">
                    <mu-icon value="thumb_up" size="20" color="teal"></mu-icon>
                  </a>
                  {{ isRoot || isAdmin ? scope.row.name + `[${scope.row.id}]` : scope.row.name }}
                </td>
                <td class="is-center">{{ scope.row.artist }}</td>
                <td class="is-center">{{ '《' + scope.row.album.name + '》' }}</td>
              </template>
            </mu-data-table>
          </mu-col>
          
          <mu-col span="6" :style="{ height: (pageHeight * 0.8) +'px' }">
            <!-- 转圈LOGO -->
            <div class="music-play-logo">
              <mu-avatar :size="250" class="image-logo">
                <img
                  :class="albumRotate ? 'album album-rotate' : 'album'"
                  :src="music.pictureUrl ? music.pictureUrl : require('../assets/images/logo.png')"
                  alt="pic"
                />
              </mu-avatar>
            </div>
            <!-- 歌曲信息 -->
            <div class="music-song-info">
              <p>歌曲名: {{ music ? music.name : '' }}</p>
              <p>专辑: {{ music.album ? '《' + music.album.name + '》' : '' }}</p>
              <p>歌手: {{ music ? music.artist : '' }}</p>
            </div>
            <!-- 歌词部分 -->
            <div style="margin-bottom:10px;height:250px">
              <Lyrics :lyrics="lyrics" :currentTime="currentTime"/>
            </div>
          </mu-col>
        </mu-row>
        <!-- 下方操作部分 -->
        <mu-row gutter class="mu-gutter-bottom">
          <mu-col span="1" class="mart40" />
          <!-- 暂停 / 下一曲 -->
          <mu-col span="1" class="mart40 play-btn">
            <mu-icon v-if="!musicPaused" value="play_arrow" class="play-btn-icon" @click="playMusic(true)" />
            <mu-icon v-else value="pause" class="play-btn-icon" @click="playMusic(false)" />
          </mu-col>
          <mu-col span="1" class="mart40 play-btn">
            <mu-icon value="skip_next" class="play-btn-icon" @click="musicSkipVote" />
          </mu-col>
          <!-- 播放进度 -->
          <mu-col span="6" class="mart40">
            <!-- 歌曲播放时间 -->
            <small id="musicEndTime" style="float: right">
              {{ playerTime }}
            </small>
            <!-- 进度条 -->
            <mu-slider v-model="progress" :display-value="false" class="demo-slider" color="#009688" />
          </mu-col>
          <mu-col span="2" class="mart40">
            <!-- 音量大小 -->
            <mu-flex justify-content="center">
              <mu-flex class="flex-demo" justify-content="start">
                <mu-icon value="volume_up" color="teal" @click="mute"/>
              </mu-flex>
              <mu-flex class="flex-demo" justify-content="start" fill>
                <mu-slider class="demo-slider" color="#009688" v-model="volume" style="color: rgb(0, 150, 136);"/>
              </mu-flex>
            </mu-flex>
          </mu-col>
          <mu-col span="1" class="mart40" />
        </mu-row>
      </div>
      <div id="blur" style="opacity: 0.4">
        <img :src="music.pictureUrl" alt="blur-img"/>
      </div>
      <!-- 播放组件 -->
      <div>
        <audio
          rel="noreferrer"
          id="music"
          :src="music.url"
          @timeupdate="musicTimeUpdate"
          controls
          autoplay="autoplay"
          @canplaythrough="nextSong"
          @canplay="setCurrentTime"
          style="display: none"
        />
      </div>
    </div>
    
    <!-- 歌曲搜索页面-->
    <mu-dialog id="search" width="100%" transition="slide-bottom" fullscreen :open.sync="openSearch">
      <mu-appbar color="primary" title="歌曲搜索">
        <mu-button slot="right" flat @click="openSearch = false; openSearchGd = true">歌单</mu-button>
        <mu-button slot="right" flat @click="openSearch = false">关闭</mu-button>
      </mu-appbar>
      <div style="width:100%;">
        <div class="music-song-search">
          <mu-text-field
            :value="searchKeyword"
            @input="updateSearchKeyword"
            @keydown.enter="search"
            :placeholder="placeHolderGq"
            color="#009688"
            class="width-size-100"
            style="text-align: center"
          >
            <mu-button class="search_btn" icon @click="search">
              <mu-icon value="search"></mu-icon>
            </mu-button>
          </mu-text-field>
        </div>
        <div class="music-song-choice">
          <mu-radio
            :value="'wy'"
            v-model="source"
            color="primary"
            :label="'网易'"
            class="searchradio music-song-search-btn"
          />
          <mu-radio
            :value="'qq'"
            v-model="source"
            color="primary"
            :label="'QQ'"
            class="searchradio music-song-search-btn"
          />
          <mu-radio
            :value="'wydt'"
            v-model="source"
            color="primary"
            :label="'电台'"
            class="searchradio music-song-search-btn"
          />
          <mu-radio
            :value="'lz'"
            v-model="source"
            color="primary"
            :label="'禁歌'"
            class="searchradio music-song-search-btn"
          />
        </div>
        <!-- 点歌搜索页面 -->
        <mu-row>
          <mu-data-table
            style="background-color: transparent; width: 70%"
            :selectable="false"
            :hover="false"
            :columns="searchColumns"
            :data="searchData"
          >
            <template slot-scope="scope">
              <td class="is-center">{{ scope.$index + 1 }}</td>
              <td class="is-left">
                <a v-if="showPickButton(scope.row.privilege)" @click="pickMusic(scope.row)">
                  <mu-avatar size="20" slot="avatar">
                    <img src="../assets/images/play.png"/>
                  </mu-avatar>
                </a>
                <mu-avatar size="20" slot="avatar" v-if="!showPickButton(scope.row.privilege)">
                  <mu-tooltip content="当前音乐不能点播">
                    <img src="../assets/images/noplay.png"/>
                  </mu-tooltip>
                </mu-avatar>
                {{ scope.row.name }}
              </td>
              <td class="is-center">{{ scope.row.artist }}</td>
              <td class="is-center">{{ '《' + scope.row.album.name + '》' }}</td>
              <td class="is-right">{{ formatterTime(scope.row.duration / 1000) }}</td>
            </template>
          </mu-data-table>
        </mu-row>
        <mu-row>
          <mu-flex justify-content="center">
            <mu-pagination
              :total="searchCount"
              :current.sync="current"
              :page-count="pageCountCompute"
              :page-size="limit"
              @change="paginationChange"
            ></mu-pagination>
          </mu-flex>
        </mu-row>
      </div>
    </mu-dialog>
    <!-- 歌单页面 -->
    <mu-dialog id="searchGd" width="100%" transition="slide-bottom" fullscreen :open.sync="openSearchGd">
      <mu-appbar color="primary" title="歌单搜索">
        <mu-button slot="right" flat @click="openSearchGd = false;openSearch = true">歌曲</mu-button>
        <mu-button slot="right" flat @click="openSearchGd = false">关闭</mu-button>
      </mu-appbar>
      <div style="width:100%;">
        <div class="music-song-search">
          <mu-text-field
            :value="searchKeywordGd"
            @input="updateSearchKeywordGd"
            @keydown.enter="searchGd"
            :placeholder="placeHolderGd"
            color="#009688"
            class="width-size-100"
            style="text-align: center"
          >
            <mu-button class="search_btn" icon @click="searchGd">
              <mu-icon value="search"></mu-icon>
            </mu-button>
          </mu-text-field>
        </div>
        <div class="music-song-choice">
          <mu-radio
            :value="'wy'"
            v-model="sourceGd"
            color="primary"
            :label="'网易'"
            class="searchradio2 music-song-search-btn"
          />
          <mu-radio
            :value="'wy_user'"
            v-model="sourceGd"
            color="primary"
            :label="'用户'"
            class="searchradio2 music-song-search-btn"
          />
          <mu-radio
            :value="'wy_userdj'"
            v-model="sourceGd"
            color="primary"
            :label="'电台'"
            class="searchradio2 music-song-search-btn"
          />
          <mu-radio
            :value="'qq'"
            v-model="sourceGd"
            color="primary"
            :label="'QQ'"
            class="searchradio music-song-search-btn"
          />
          <mu-radio
            :value="'qq_user'"
            v-model="sourceGd"
            color="primary"
            :label="'用户'"
            class="searchradio music-song-search-btn"
          />
        </div>
      </div>
      <mu-row>
        <mu-data-table
          style="background-color: transparent; width: 70%"
          :selectable="false"
          :hover="false"
          :columns="searchColumnsGd"
          :data="searchDataGd"
        >
          <template slot-scope="scope2">
            <td class="is-center">{{ scope2.$index + 1 }}</td>
            <td class="is-left">
              <a @click="songlistDetail(scope2.row)">{{ scope2.row.name }}</a>
            </td>
            <td class="is-center">{{ scope2.row.desc|ellipsis }}</td>
            <td class="is-center">{{ scope2.row.playCount }}</td>
            <td class="is-center">{{ scope2.row.songCount }}</td>
          </template>
        </mu-data-table>
      </mu-row>
      <mu-row>
        <mu-flex justify-content="center">
          <mu-pagination
            :total="searchCountGd"
            :current.sync="currentGd"
            :page-count="pageCountCompute"
            :page-size="limit"
            @change="paginationChangeGd"
          />
        </mu-flex>
      </mu-row>
    </mu-dialog>
    <!-- 操作栏-->
    <mu-bottom-sheet id="sheet" :open.sync="open" style="min-height: 300px; max-height:500px;overflow:auto;">
      <mu-list>
        <mu-sub-header>
          <mu-button flat color="primary" @click="playAll">
            全部播放
          </mu-button>
          <mu-button flat color="primary" @click="exportCollect">
            导出
          </mu-button>
          <mu-button flat color="primary" @click="importCollect">
            导入
          </mu-button>
          <input ref="fileInput" type="file" accept="application/json" style="display: none"
                 @change="handleFileInputChange">
          <mu-button flat color="primary" @click="removeAllCollect">
            清空收藏
          </mu-button>
          <mu-button flat color="primary" @click="backServer">
            远程备份
          </mu-button>
          <mu-button flat color="primary" @click="reductionServer()">
            远程还原
          </mu-button>
          <mu-text-field v-model="serverKey"/>
          <mu-button flat color="primary" style="right: 0;position: absolute;" @click="open = false">
            关闭
          </mu-button>
        </mu-sub-header>
        <!-- 收藏歌单列表 -->
        <mu-list-item v-for="(item,index) in sortCollect" :key="item.key">
          <mu-list-item-action @click="removeCollect(item.value)" style="width:10%;">
            <mu-icon value="favorite" color="red"></mu-icon>
          </mu-list-item-action>
          <mu-list-item-action @click="pickMusicNoToast(item.value)" style="width:10%;">
            <mu-icon value="play_arrow" color="teal"></mu-icon>
          </mu-list-item-action>
          <mu-list-item-title style="width:80%;">
            {{ index + 1 }}.{{ item.value.name }}|{{ item.value.artist }}|{{ item.value.album.name }}
          </mu-list-item-title>
        </mu-list-item>
      </mu-list>
    </mu-bottom-sheet>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import {messageUtils, musicUtils, sendUtils, timeUtils} from '@/utils'
import {baseUrl, isProduction, kuwoHttps, jsonServerUrl} from '@/config/environment'
import Lyrics from '../components/Lyrics'
import axios from 'axios'

export default {
  name: 'Music',
  components: {
    Lyrics
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 20) {
        return value.slice(0, 20) + '...'
      }
      return value
    }
  },
  computed: {
    pageCountCompute() {
      if (!this.pageCount || this.pageCount < 5) {
        return 5
      }
      return this.pageCount % 2 === 0 ? this.pageCount + 1 : this.pageCount
    },
    sortCollect() {
      // 将对象的键值对转换为数组
      const array = Object.entries(this.favoriteMap)
      
      // 使用 JavaScript 的 sort 方法对数组按 name 字段排序
      array.sort((a, b) => {
        return b[1].pickTime - a[1].pickTime
      })
      
      // 将排序后的数组映射为包含键值对的对象
      return array.map(([key, value]) => {
        return {key, value}
      })
    },
    ...mapGetters({
      online: 'getSocketOnline',
      chatMessage: 'getChatMessage',
      music: 'getPlayerMusic',
      progress: 'getPlayerProgress',
      playerTime: 'getPlayerTime',
      pick: 'getPlayerPick',
      lyric: 'getPlayerLyric',
      isRoot: 'isSocketRoot',
      isAdmin: 'isSocketAdmin',
      good: 'isSocketGood',
      circle: 'isSocketCircle',
      searchKeyword: 'getSearchKeyword',
      searchKeywordGd: 'getSearchKeywordGd',
      searchKeywordUser: 'getSearchKeywordUser',
      searchData: 'getSearchData',
      searchDataGd: 'getSearchDataGd',
      searchDataUser: 'getSearchDataUser',
      searchCount: 'getSearchCount',
      searchCountGd: 'getSearchCountGd',
      searchCountUser: 'getSearchCountUser',
      music2: 'getMusic2'
    }),
    ...mapMutations({
      // volume: 'setPlayerVolume'
    }),
    volume: {
      get: function () {
        return this.$store.getters.getPlayerVolume
      },
      set: function (value) {
        music.volume = Number(value) / 100
        this.$store.commit('setPlayerVolume', value)
      }
    }
  },
  data: () => ({
    serverKey: '',
    volumeBack: 50,
    albumRotate: false,
    openSearch: false,
    openSearchGd: false,
    columns: [
      {title: '收藏', name: 'sort', width: 50, align: 'center'},
      {title: '序号', name: 'id', width: 30, align: 'center'},
      {title: '歌曲', name: 'name', width: 200, align: 'left'},
      {title: '歌手', name: 'calories', align: 'center'},
      {title: '专辑', name: 'fat', align: 'center'}
    ],
    searchColumns: [
      {title: '序号', name: 'id', width: 50, align: 'center'},
      {title: '歌曲', name: 'name', minWidth: 350, align: 'left'},
      {title: '歌手', name: 'artist', align: 'center'},
      {title: '专辑', name: 'album', align: 'center'},
      {title: '时长', name: 'duration', align: 'right'}
    ],
    searchColumnsGd: [
      {title: '序号', name: 'id', width: 50, align: 'center'},
      {title: '歌单', name: 'name', width: 350, align: 'left'},
      {title: '描述', name: 'desc', align: 'center'},
      {title: '播放量', name: 'playCount', align: 'center'},
      {title: '曲数', name: 'songCount', align: 'center'}
    ],
    current: 1,
    currentGd: 1,
    limit: 10,
    pageCount: 7,
    source: 'wy',
    sourceGd: 'wy',
    sourceChat: 'wy',
    sourceUser: 'wy',
    homeHouse: {
      name: '就随便叫了',
      desc: '',
      password: 'dsfsafds312@321',
      needPwd: true,
      enableStatus: false,
      retainKey: ''
    },
    secondUrl: '',
    firstLoaded: 0,
    homeHouses: [],
    loading: {},
    houseForward: '',
    favorite: false,
    playingId: '',
    musicPaused: false,
    housePwd: '123',
    connectType: '',
    houseIdNoAction: '',
    housePwdNoAction: '123',
    connectTypeNoAction: '',
    placeHolderGd: '试下为空搜索下(*^__^*)',
    placeHolderGq: '请输入关键字搜索',
    houseReachId: '',
    closeClock: null,
    announceToast: null,
    lastLyric: '',
    currentLyric: '',
    favoriteMap: {},
    open: false,
    currentTime: 0,
    lyrics: {},
    houseSearch: '',
    houseHide: true,
    pageHeight: 800
  }),
  watch: {
    'window.innerHeight': {
      handler(val) {
       this.pageHeight = val
      }
    },
    sourceGd: {
      handler() {
        this.currentGd = 1
        let placeholders = ['搜索[民谣]来听下吧', '试下为空搜索(*^__^*)', '请输入关键字，如\'摇滚\'']
        this.placeHolderGd = placeholders[Math.floor(Math.random() * 3)]
      }
    },
    source: {
      handler() {
        this.current = 1
        let placeholders = ['请输入关键字搜索,如\'遇见\'', '歌单id搜索:\'*歌单id\'', '不知听啥，点右上歌单...']
        this.placeHolderGq = placeholders[Math.floor(Math.random() * 3)]
      }
    },
    playerTime: {
      handler() {
        if (document.querySelector('#music')) {
          this.musicPaused = document.querySelector('#music')
        }
      },
      deep: true
    },
    playingId: function (newValue, oldValue) {
      let _this = this
      // console.log("新等于旧");
      if (newValue !== '' && newValue !== oldValue) {
        this.albumRotate = false
        document.querySelector('#music').volume =
          Number(this.$store.getters.getPlayerVolume) / 100
        // console.log("新不等于旧");
        setTimeout(function () {
          _this.albumRotate = true
          let pushTime = _this.$store.getters.getPlayerMusic.pushTime
          if (pushTime) {
            // console.log("currenttime:",document.querySelector("#music").currentTime);
            if ((Date.now() - pushTime) / 1000 - document.querySelector('#music').currentTime > 1) {
              document.querySelector('#music').currentTime =
                (Date.now() - pushTime) / 1000
            }
            _this.createTouchstartEventAndDispatch(document)
            
          }
        }, 1000)
      }
    },
    '$store.state.player.music': {
      handler() {
        // var audio = document.getElementById("music");
        // 解决部分移动端不能自动播放
        document.addEventListener('touchstart', function () {
          if (document.getElementById('music') !== undefined && document.getElementById('music') !== null) {
            document.getElementById('music').play()
          }
        })
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.$http.defaults.baseURL = baseUrl
      // 初始化加载. 创建房间
      this.createHomeHouse()
    })
  },
  created() {
  },
  methods: {
    play: function () {
      this.connect()
    },
    connect: function () {
      let _this = this
      let socketClient = this.$store.getters.getSocketClient
      let stompClient = this.$store.getters.getStompClient
      
      socketClient = new SockJS(baseUrl + '/server?houseId=' + this.houseId + '&housePwd=' + this.housePwd + '&connectType=' + this.connectType) ////// new SockJS("https://www.alang.run" + "/wss?houseId="+this.houseId+"&housePwd="+this.housePwd+"&connectType="+this.connectType);/
      stompClient = Stomp.over(socketClient)
      
      if (isProduction) {
        stompClient.debug = () => {
        }
      }
      stompClient.connect(
        {},
        frame => {
          // console.log('连接到服务器成功！', frame);
          this.$store.commit('setSocketIsConnected', true)
          // pre onmessage
          let afterOnMessage = socketClient.onmessage
          socketClient.onmessage = function (message) {
            _this.messageHandler(message)
            afterOnMessage(message)
          }
          
          // pre onclose
          let afterOnclose = socketClient.onclose
          socketClient.onclose = function (e) {
            if (e.type === 'close') {
              _this.$store.commit('setSocketIsConnected', false)
              _this.$store.commit('pushChatData', {
                type: 'notice',
                content: '网络异常, 请尝试重新连接服务器!'
              })
              _this.$toast.error('网络异常, 请尝试重新连接服务器!')
              setTimeout(function () {
                if (!_this.$store.getters.getIsConnected) {
                  _this.connect()
                }
              }, 444)
            }
            afterOnclose(e)
          }
          
          let userName = window.localStorage.getItem('USER_NAME')
          if (userName) {
            this.settingName(userName)
          }
          this.subscribe()
        }
      )
      this.saveSocket(socketClient, stompClient)
    },
    close: function () {
      let socketClient = this.$store.getters.getSocketClient
      let stompClient = this.$store.getters.getStompClient
      
      stompClient.disconnect()
      socketClient.close()
      this.playingId = ''
      
      this.saveSocket(socketClient, stompClient)
      
    },
    subscribe: function () {
      console.log('订阅')
      let stompClient = this.$store.getters.getStompClient
      stompClient.subscribe('/topic/chat', response => {
        let body = JSON.parse(response.body)
        if (body.code === '20000') {
          this.$toast.message({message: '系统通知：' + body.data, time: 30 * 1000, closeIcon: 'close', close: true})
        }
      })
      
      stompClient.subscribe('/topic/music/order', response => {
      })
      
      this.saveSocket(null, stompClient)
    },
    saveSocket: function (socketClient, stompClient) {
      if (socketClient !== null) {
        this.$store.commit('setSocketClient', socketClient)
      }
      if (stompClient !== null) {
        this.$store.commit('setStompClient', stompClient)
      }
    },
    // todo 播放相关接口
    sendHandler: function () {
      let stompClient = this.$store.getters.getStompClient
      let chatMessage = this.$store.getters.getChatMessage
      
      let instruction = sendUtils.parseInstruction(chatMessage)
      let content = ''
      
      switch (instruction) {
        case '点歌':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入音乐关键词', chatMessage)
          } else {
            stompClient.send(
              '/music/pick',
              {},
              JSON.stringify({
                name: content,
                source: this.sourceChat,
                sendTime: Date.now()
              })
            )
          }
          break
        case '投票切歌':
          this.musicSkipVote()
          break
        case '设置昵称':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入昵称', chatMessage)
          } else {
            this.settingName(content)
          }
          break
        case '通知':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入公告', chatMessage)
          } else {
            stompClient.send('/chat/notice/' + content, {}, JSON.stringify({}))
          }
          break
        case '公告':
          content = sendUtils.parseContent(instruction, chatMessage)
          stompClient.send('/chat/announce', {}, JSON.stringify({content: content}))
          break
        case 'root':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入 root 密码', chatMessage)
          } else {
            stompClient.send(
              '/auth/root',
              {},
              JSON.stringify({
                password: content,
                sendTime: Date.now()
              })
            )
          }
          break
        case 'admin':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入 admin 密码', chatMessage)
          } else {
            stompClient.send(
              '/auth/admin',
              {},
              JSON.stringify({
                password: content,
                sendTime: Date.now()
              })
            )
          }
          break
        case '置顶音乐':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入要置顶的音乐 id', chatMessage)
          } else {
            stompClient.send(
              '/music/top',
              {},
              JSON.stringify({
                id: content,
                sendTime: Date.now()
              })
            )
          }
          break
        case '删除音乐':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入要删除的音乐 id', chatMessage)
          } else {
            stompClient.send(
              '/music/delete',
              {},
              JSON.stringify({
                id: content,
                sendTime: Date.now()
              })
            )
          }
          break
        case '设置默认列表':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入要删除的音乐 id', chatMessage)
          } else {
            stompClient.send(
              '/music/setDefaultPlaylist',
              {},
              JSON.stringify({
                id: content,
                source: this.sourceChat
              })
            )
          }
          break
        case '清空列表':
          stompClient.send('/music/clear', {}, '')
          break
        case '清空默认列表':
          stompClient.send('/music/clearDefaultPlayList', {}, '')
          break
        case '音乐黑名单':
          stompClient.send('/music/blackmusic', {}, '')
          break
        case '默认列表歌曲数':
          stompClient.send('/music/playlistSize', {}, '')
          break
        case '用户黑名单':
          stompClient.send('/chat/blackuser', {}, '')
          break
        case '调整音量':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            content = 0
          }
          
          stompClient.send('/music/volumn/' + content, {}, '')
          
          break
        case '倒计时退出':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (!/^\d+$/.test(content)) {
            this.$toast.message('请输入要在几分钟后退出')
          } else {
            this.setTimeToClose(content)
            this.$toast.message('设置成功，将在' + content + '分钟后退出')
          }
          break
        case '取消退出':
          this.setTimeToClose(0)
          this.$toast.message('取消成功')
          break
        case '修改密码':
          content = sendUtils.parseContent(instruction, chatMessage)
          
          stompClient.send('/auth/adminpwd/' + content, {}, '')
          
          break
        case '修改root密码':
          content = sendUtils.parseContent(instruction, chatMessage)
          
          stompClient.send('/auth/rootpwd/' + content, {}, '')
          
          break
        case '投票切歌率':
          content = sendUtils.parseContent(instruction, chatMessage)
          
          stompClient.send('/music/vote/' + content, {}, '')
          
          break
        case '点赞模式':
          stompClient.send('/music/goodmodel/true', {}, '')
          break
        case '退出点赞模式':
          stompClient.send('/music/goodmodel/false', {}, '')
          break
        case '单曲循环':
          stompClient.send('/music/musiccirclemodel/true', {}, '')
          break
        case '列表循环':
          stompClient.send('/music/musiclistmodel/true', {}, '')
          break
        case '退出单曲循环':
          stompClient.send('/music/musiccirclemodel/false', {}, '')
          break
        case '退出列表循环':
          stompClient.send('/music/musiclistmodel/false', {}, '')
          break
        case '随机模式':
          stompClient.send('/music/randommodel/true', {}, '')
          break
        case '退出随机模式':
          stompClient.send('/music/randommodel/false', {}, '')
          break
        case '留存房间':
          stompClient.send('/house/retain/true', {}, '')
          break
        case '不留存房间':
          stompClient.send('/house/retain/false', {}, '')
          break
        case '禁止点歌':
          stompClient.send('/music/banchoose/true', {}, '')
          break
        case '禁止切歌':
          stompClient.send('/music/banswitch/true', {}, '')
          break
        case '启用切歌':
          stompClient.send('/music/banswitch/false', {}, '')
          break
        case '启用点歌':
          stompClient.send('/music/banchoose/false', {}, '')
          break
        case '拉黑用户':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入要拉黑的用户 session', chatMessage)
          } else {
            stompClient.send(
              '/chat/black',
              {},
              JSON.stringify({
                sessionId: content,
                sendTime: Date.now()
              })
            )
          }
          break
        case '漂白用户':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入要漂白的用户 session', chatMessage)
          } else {
            stompClient.send(
              '/chat/unblack',
              {},
              JSON.stringify({
                sessionId: content,
                sendTime: Date.now()
              })
            )
          }
          break
        case '设置点歌人':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入要拉黑的用户 session', chatMessage)
          } else {
            stompClient.send(
              '/auth/setPicker/' + content,
              {},
              ''
            )
          }
          break
        case '取消点歌人':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入要拉黑的用户 session', chatMessage)
          } else {
            stompClient.send(
              '/auth/setNoPicker/' + content,
              {},
              ''
            )
          }
          break
        case '设置切歌人':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入要拉黑的用户 session', chatMessage)
          } else {
            stompClient.send(
              '/auth/setVoter/' + content,
              {},
              ''
            )
          }
          break
        case '取消切歌人':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入要拉黑的用户 session', chatMessage)
          } else {
            stompClient.send(
              '/auth/setNoVoter/' + content,
              {},
              ''
            )
          }
          break
        case '拉黑音乐':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
            // console.log('请输入要拉黑的音乐 id', chatMessage);
          } else {
            stompClient.send(
              '/music/black',
              {},
              JSON.stringify({
                id: content,
                sendTime: Date.now()
              })
            )
          }
          break
        case '漂白音乐':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
          } else {
            // console.log('请输入要漂白的音乐 id', chatMessage);
            stompClient.send(
              '/music/unblack',
              {},
              JSON.stringify({
                id: content,
                sendTime: Date.now()
              })
            )
          }
          break
        case '@管理员':
          content = sendUtils.parseContent(instruction, chatMessage)
          if (content === '') {
          } else {
            stompClient.send(
              '/mail/send',
              {},
              JSON.stringify({
                content: content,
                sendTime: Date.now()
              })
            )
          }
          break
        default:
          if (
            chatMessage === null ||
            chatMessage === '' ||
            chatMessage.length === 0
          ) {
            // console.log('消息非法', chatMessage);
          } else {
            stompClient.send(
              '/chat',
              {},
              JSON.stringify({
                content: chatMessage,
                sendTime: Date.now()
              })
            )
          }
          break
      }
      
      this.$store.commit('setChatMessage', '')
    },
    messageHandler: function (source) {
      console.log('消息处理器收到消息', source);
      if (messageUtils.isKnowMessageType(source.data)) {
        let messageType = messageUtils.parseMessageType(source.data)
        let messageContent = messageUtils.parseMessageContent(source.data)
        switch (messageType) {
          case messageUtils.messageType.ONLINE:
            if (
              messageContent.data.count !== undefined &&
              typeof messageContent.data.count !== 'undefined' &&
              messageContent.data.count !== null &&
              messageContent.data.count !== ''
            ) {
              this.$store.commit('setSocketOnline', messageContent.data.count)
            }
            break
          case messageUtils.messageType.HOUSE_USER:
            let users = messageContent.data
            for (let i = 0; i < users.length; i++) {
              this.$store.commit('pushChatData', {
                content: (i + 1) + '.' + users[i].nickName + '[' + users[i].sessionId + ']',
                type: 'notice'
              })
            }
            break
          case messageUtils.messageType.NOTICE:
            if (
              messageContent.message !== undefined &&
              typeof messageContent.message !== 'undefined' &&
              messageContent.message !== null &&
              messageContent.message !== ''
            ) {
              this.$store.commit('pushChatData', {
                content: messageContent.message,
                type: 'notice'
              })
              if (messageContent.message === '点歌成功')
                this.$toast.message({message: messageContent.message, time: 1000})
            } else {
              this.$toast.message(messageContent.message)
            }
            break
          case messageUtils.messageType.ANNOUNCEMENT:
            if (this.announceToast) {
              this.$toast.close(this.announceToast)
            }
            if (messageContent.data.content) {
              this.announceToast = this.$toast.message({
                message: '公告：' + messageContent.data.content,
                time: 60 * 1000,
                closeIcon: 'close',
                close: true
              })
            }
            break
          
          case messageUtils.messageType.CHAT:
            // parse picture
            let imgList = []
            let matchUrlList = messageContent.data.content.match(
              /[picture].*?:\/\/[^\s]*/gi
            )
            if (matchUrlList !== null) {
              for (let i = 0; i < matchUrlList.length; i++) {
                imgList.push(matchUrlList[i].replace('picture:', ''))
                messageContent.data.content = messageContent.data.content.replace(
                  matchUrlList[i],
                  ''
                )
              }
            }
            messageContent.data.images = imgList
            this.$store.commit('pushChatData', messageContent.data)
            break
          case messageUtils.messageType.GOODMODEL:
            var data = messageContent.data
            if (data === 'GOOD') {
              this.$store.commit('setSocketGood', true)
            } else {
              this.$store.commit('setSocketGood', false)
            }
            // this.$forceUpdate();
            
            break
          case messageUtils.messageType.PICK:
            if (messageContent.message === 'goodlist') {
              this.$store.commit('setSocketGood', true)
            }
            this.$store.commit('setPlayerPick', messageContent.data)
            if (messageContent.data.length > 1) {
              this.secondUrl = messageContent.data[1].url
              //console.log("this.firstLoaded"+this.firstLoaded);
              if (this.firstLoaded === 1) {
                this.$store.commit('setMusic2', {url: this.secondUrl})
              }
            }
            break
          case messageUtils.messageType.VOLUMN:
            //console.log(messageContent.data);
            // music.volume = Number(messageContent.data) / 100
            // this.$store.commit('setPlayerVolume', messageContent.data)
            
            break
          case messageUtils.messageType.MUSIC:
            this.lastLyric = ''
            this.$store.commit('setPlayerLyric', '')
            this.firstLoaded = 0
            if (kuwoHttps !== '' && messageContent.data.url.indexOf('kuwo.cn') !== -1 && messageContent.data.url.indexOf('-') === -1) {
              let urls = messageContent.data.url.split('.sycdn.')
              let headUrls = urls[0].replace('http://', '').split('.')
              let lastHeadUrl = headUrls[headUrls.length - 1]
              messageContent.data.url = 'https://' + lastHeadUrl + '-sycdn.' + urls[1] + '&timestamp=' + Date.now()
              //messageContent.data.url = kuwoHttps +"?timestamp="+Date.now()+"&targetUrl="+messageContent.data.url;
            } else {
              if (messageContent.data.url.indexOf('?') !== -1) {
                messageContent.data.url = messageContent.data.url + '&timestamp=' + Date.now()
              } else {
                messageContent.data.url = messageContent.data.url + '?timestamp=' + Date.now()
              }
              if (messageContent.data.url.indexOf('/ymusic/') !== -1) {
                messageContent.data.url = messageContent.data.url.replace(/(m\d+?)(?!c)\.music\.126\.net/, '$1c.music.126.net')//wy403RedirectUrl+"?targetUrl="+messageContent.data.url;
              }
            }
            messageContent.data.url = messageContent.data.url.replace('http://', 'https://')
            // console.log("看这里");
            // console.log(messageContent.data.url);
            // console.log(kuwoHttps);
            // console.log(messageContent.data.url.indexOf("kuwo.cn") !== -1);
            this.$store.commit('setPlayerMusic', messageContent.data)
            document.querySelector('#music').preload = 'auto'
            if (
              messageContent.data.lyric === undefined ||
              typeof messageContent.data.lyric === 'undefined' ||
              messageContent.data.lyric === null ||
              messageContent.data.lyric === ''
            ) {
              this.$store.commit('setPlayerLyrics', [])
            } else {
              this.lyrics = musicUtils.parseLyric(messageContent.data.lyric)
              this.$store.commit(
                'setPlayerLyrics',
                this.lyrics
              )
            }
            document.title = messageContent.data.name
            break
          case messageUtils.messageType.AUTH_ROOT:
            this.$store.commit('pushChatData', {
              content: messageContent.message,
              type: 'notice'
            })
            if (Number(messageContent.code) === 20000) {
              this.$store.commit('setSocketRoot', true)
              // console.log('root success')
            } else {
              this.$store.commit('setSocketRoot', false)
            }
            break
          case messageUtils.messageType.ENTER_HOUSE_START:
            if (Number(messageContent.code) === 20000) {
              this.$store.commit('setPlayerPick', new Array())
            }
            break
          case messageUtils.messageType.ADD_HOUSE_START:
            if (Number(messageContent.code) === 20000) {
              this.$store.commit('setPlayerPick', new Array())
            }
            break
          case messageUtils.messageType.ENTER_HOUSE:
            this.loading.close()
            if (Number(messageContent.code) === 20000) {
              this.houseId = this.houseIdNoAction
              this.housePwd = this.housePwdNoAction
              this.connectType = this.connectTypeNoAction
              this.musichouse = this.houseForward
              document
                .querySelectorAll('.mu-tooltip')
                .forEach(el => (el.style.display = 'none'))
              let userName = window.localStorage.getItem('USER_NAME')
              if (userName) {
                this.settingName(userName)
              }
            } else {
              this.$toast.message(messageContent.message)
            }
            break
          case messageUtils.messageType.AUTH_ADMIN:
            this.$store.commit('pushChatData', {
              content: messageContent.message,
              type: 'notice'
            })
            if (Number(messageContent.code) === 20000) {
              this.$store.commit('setSocketAdmin', true)
              // console.log('admin success')
            } else {
              this.$store.commit('setSocketAdmin', false)
            }
            break
          case messageUtils.messageType.SETTING_NAME:
            this.$store.commit('pushChatData', {
              content: messageContent.message,
              type: 'notice'
            })
            this.$store.commit('setSocketUserName', messageContent.data.name)
            break
          case messageUtils.messageType.SEARCH:
            this.$store.commit('setSearchCount', messageContent.data.totalSize)
            this.$store.commit('setSearchData', messageContent.data.data)
            break
          case messageUtils.messageType.SEARCH_SONGLIST:
            this.$store.commit('setSearchCountGd', messageContent.data.totalSize)
            this.$store.commit('setSearchDataGd', messageContent.data.data)
            break
          case messageUtils.messageType.SEARCH_USER:
            this.$store.commit('setSearchCountUser', messageContent.data.totalSize)
            this.$store.commit('setSearchDataUser', messageContent.data.data)
            break
          case messageUtils.messageType.SEARCH_PICTURE:
            this.$store.commit(
              'setSearchPictureCount',
              messageContent.data.totalSize
            )
            this.$store.commit(
              'setSearchPictureData',
              messageContent.data.data
            )
            break
          default:
            // console.log('未知消息类型', messageType, source);
            break
        }
      }
    },
    updateSearchKeyword: function (value) {
      this.$store.commit('setSearchKeyword', value)
    },
    updateSearchKeywordGd: function (value) {
      this.$store.commit('setSearchKeywordGd', value)
    },
    updateSearchKeywordUser: function (value) {
      this.$store.commit('setSearchKeywordUser', value)
    },
    settingName: function (name) {
      let stompClient = this.$store.getters.getStompClient
      stompClient.send(
        '/setting/name',
        {},
        JSON.stringify({
          name: name,
          sendTime: Date.now()
        })
      )
    },
    search: function () {
      let stompClient = this.$store.getters.getStompClient
      stompClient.send(
        '/music/search',
        {},
        JSON.stringify({
          name: this.$store.getters.getSearchKeyword.trim(),
          sendTime: Date.now(),
          source: this.source,
          pageIndex: this.current,
          pageSize: this.limit
        })
      )
    },
    searchGd: function () {
      let stompClient = this.$store.getters.getStompClient
      stompClient.send(
        '/music/searchsonglist',
        {},
        JSON.stringify({
          name: (this.$store.getters.getSearchKeywordGd + '').trim(),
          sendTime: Date.now(),
          source: this.sourceGd,
          pageIndex: this.currentGd,
          pageSize: this.limit
        })
      )
    },
    paginationChange: function (page) {
      this.current = page
      this.search()
    },
    paginationChangeGd: function (page) {
      this.currentGd = page
      this.searchGd()
    },
    goodMusic: function (row) {
      let stompClient = this.$store.getters.getStompClient
      stompClient.send('/music/good/' + row.id, {}, {})
      this.$toast.message(`[${row.id}]${row.name} - 已发送点赞请求`)
    },
    pickMusic: function (row) {
      let stompClient = this.$store.getters.getStompClient
      stompClient.send(
        '/music/pick',
        {},
        JSON.stringify({
          name: row.name,
          id: row.id,
          source: this.source,
          sendTime: Date.now()
        })
      )
      this.$toast.message(`[${row.id}]${row.name} - 已发送点歌请求`)
    },
    pickMusicNoToast: function (row) {
      let stompClient = this.$store.getters.getStompClient
      stompClient.send(
        '/music/pick',
        {},
        JSON.stringify({
          name: row.name,
          id: row.id,
          source: row.source,
          sendTime: Date.now()
        })
      )
    },
    
    showPickButton(value) {
      if (Number(value.st) < 0) {
        // 没有资源
        return false
      } else if (Number(value.fl) === 0) {
        // 可能需要付费
        return false
      }
      return true
    },
    songlistDetail(value) {
      this.openSearchGd = false
      this.openSearch = true
      this.$store.commit('setSearchKeyword', '*' + value.id)
      this.source = this.sourceGd.startsWith('wy') ? (this.sourceGd.endsWith('userdj') ? 'wydt' : 'wy') : 'qq'
      this.current = 1
      this.search()
    },
    musicSkipVote: function () {
      console.log('切歌')
      let stompClient = this.$store.getters.getStompClient
      stompClient.send('/music/skip/vote', {}, {})
    },
    // 播放
    musicTimeUpdate: function (e) {
      // progress
      this.currentTime = e.target.currentTime
      let duration = e.target.duration
      this.$store.commit('setPlayerProgress', (this.currentTime / duration) * 100)
      
      // show time
      let usedTimeHH_mm_ss = timeUtils.secondsToHH_mm_ss(this.currentTime)
      let durationHH_mm_ss = timeUtils.secondsToHH_mm_ss(duration)
      let time = usedTimeHH_mm_ss + ' / ' + durationHH_mm_ss
      this.$store.commit('setPlayerTime', time)
      
      // lyric
      this.lyrics = this.$store.getters.getPlayerLyrics
      if (this.lyrics.length === 0) {
        this.$store.commit('setPlayerLyric', '暂无歌词')
      } else {
        let number = Number(this.currentTime.toFixed())
        if (this.lyrics[number] !== undefined && this.lyrics[number] !== '' && this.lyrics[number] !== this.currentLyric) {
          this.lastLyric = this.currentLyric
          this.currentLyric = this.lyrics[number]
          this.$store.commit('setPlayerLyric', this.lyrics[number])
        }
      }
    },
    formatterTime: function (value) {
      return timeUtils.secondsToHH_mm_ss(value)
    },
    nextSong: function (e) {
      this.firstLoaded = 1
      this.$store.commit('setMusic2', {url: this.secondUrl})
    },
    createHomeHouse() {
      this.loading = this.$loading({overlayColor: 'hsla(0, 0%, 100%, .5)'})
      this.$http.post('/house/add', {
        name: this.homeHouse.name,
        desc: this.homeHouse.desc,
        needPwd: this.homeHouse.needPwd,
        password: this.homeHouse.password,
        enableStatus: this.homeHouse.enableStatus,
        retainKey: this.homeHouse.retainKey
      }).then(response => {
        this.loading.close()
        if (response.data.code === '20000' || response.data.code === '40000') {
          this.houseId = response.data.data
          this.housePwd = this.homeHouse.password
          this.connectType = ''
          this.play()
          this.musichouse = this.homeHouse.name
          document
            .querySelectorAll('.mu-tooltip')
            .forEach(el => (el.style.display = 'none'))
        } else {
          this.$toast.message(response.data.message)
        }
      }).catch(error => {
        this.loading.close()
      })
    },
    setCurrentTime() {
      this.playingId = this.$store.getters.getPlayerMusic.id + this.$store.getters.getPlayerMusic.source + this.$store.getters.getPlayerMusic.pushTime
    },
    mute() {
      if (this.volume === 0) {
        this.volume = this.volumeBack
      } else {
        this.volumeBack = this.volume
        this.volume = 0
      }
    },
    playMusic(status) {
      this.musicPaused = status
      if (this.musicPaused) {
        document.querySelector('#music').play()
      } else {
        document.querySelector('#music').pause()
      }
    },
    createTouchstartEventAndDispatch(el) {
      try {
        let event = document.createEvent('Events')
        event.initEvent('touchstart', true, true)
        el.dispatchEvent(event)
      } catch (Exception) {
      }
    },
    setTimeToClose(minutes) {
      if (this.closeClock) {
        window.clearTimeout(this.closeClock)
      }
      if (minutes !== 0) {
        this.closeClock = window.setTimeout(this.close, minutes * 60 * 1000)
      }
    },
    searchTop() {
      this.openSearch = true
      this.$store.commit('setSearchKeyword', '*热歌榜')
      this.source = 'wy'
      this.current = 1
      this.search()
    },
    // 加入收藏
    collectMusic(row) {
      // 获取json
      this.reductionServer(() => {
        row.pickTime = new Date().getTime()
        this.$set(this.favoriteMap, row.id, row)
        // 保存至远程
        this.backServer()
      })
    },
    // 删除收藏
    removeCollect(row) {
      this.reductionServer(() => {
        row.pickTime = new Date().getTime()
        this.$delete(this.favoriteMap, row.id)
        // 保存至远程
        this.backServer()
      })
    },
    // 清空收藏
    removeAllCollect() {
      localStorage.removeItem('collectMusic')
      this.favoriteMap = {}
      this.open = false
    },
    // 还原远程
    reductionServer(callBack) {
      console.log('还原')
      if (this.$route.query.key) {
        this.serverKey = this.$route.query.key
      }
      if (this.serverKey) {
        var warApi = jsonServerUrl + this.serverKey
        axios.get(warApi).then(response => {
          this.favoriteMap = response.data.data
          localStorage.setItem('collectMusic', JSON.stringify(this.favoriteMap))
          this.$toast.message('还原收藏')
          if (callBack !== undefined) {
            callBack()
          }
        }).catch(() => {
          console.log('error')
          this.$toast.message('请求异常!')
        })
      } else {
        this.$toast.message('请先填写key')
      }
    },
    // 备份至远程
    backServer() {
      console.log('备份')
      if (this.$route.query.key) {
        this.serverKey = this.$route.query.key
      }
      if (this.serverKey) {
        const dataStr = JSON.stringify(this.favoriteMap, null, 2)
        if (Object.keys(JSON.parse(dataStr)).length > 0) {
          axios(jsonServerUrl + this.serverKey, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            data: {jsonData: dataStr.replaceAll('\n', '')},
            responseType: 'json'
          }).then(response => {
            console.log(response)
            if (response.status === 200 && response.data.code === 200) {
              this.$toast.message(response.data.msg)
            } else {
              this.$toast.message(response.data.msg)
            }
          })
        } else {
          this.$toast.message('无内容可备份')
        }
      } else {
        this.$toast.message('请先填写key')
      }
    },
    exportCollect() {
      const dataStr = JSON.stringify(this.favoriteMap, null, 2)
      const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr)
      const exportFileDefaultName = 'music_' + timeUtils.secondsToYYYY_MM_dd_HH_mm_ss() + '.json'
      const linkElement = document.createElement('a')
      linkElement.setAttribute('href', dataUri)
      linkElement.setAttribute('download', exportFileDefaultName)
      linkElement.click()
    },
    importCollect() {
      this.$refs.fileInput.click()
      this.$nextTick(() => {
        this.backServer()
      })
    },
    handleFileInputChange(event) {
      const files = event.target.files
      if (files.length === 0) return
      const file = files[0]
      const reader = new FileReader()
      reader.onload = () => {
        try {
          const data = JSON.parse(reader.result)
          for (let key in data) {
            this.$set(this.favoriteMap, key, data[key])
          }
          localStorage.setItem('collectMusic', JSON.stringify(this.favoriteMap))
          // 处理导入的JSON数据
        } catch (error) {
          console.error('JSON文件格式错误：', error)
        }
      }
      reader.readAsText(file)
    },
    playAll() {
      let _this = this
      for (let key in this.favoriteMap) {
        _this.pickMusicNoToast(_this.favoriteMap[key])
      }
      this.open = false
      // 播放全部后, 自动切歌
      this.$nextTick(() => {
        this.musicSkipVote()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.album {
  width: 100%;
  display: inline-block;
  cursor: pointer;
  transition-duration: 0.2s;
  padding: 4px;
  border: 32px solid rgb(16, 16, 16);
  border-radius: 50%;
  background: linear-gradient(
      rgb(39, 39, 39),
      rgb(0, 0, 0),
      rgb(0, 0, 0),
      rgb(39, 39, 39)
  );
  /*box-shadow: 0 0 20px 2px #000;*/
}

.album-rotate {
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}
.container-main {
  display: flex;
  flex-direction: column;
  height: 100vh; /* 100% 浏览器视口高度 */
}
.demo-container {
  padding: 0 20px 0 20px;
}
.table-style {
  background-color: transparent;
  overflow: auto;
  width: 100%;
}
</style>
