/**
 * 环境配置
 *
 * @type {string}
 */

let baseUrl = '';
let kuwoHttps = '';

if (process.env.NODE_ENV === "development") {
    baseUrl = "https://tx.alang.run/api";
    kuwoHttps = "https://tx.alang.run/kuwo";
} else if (process.env.NODE_ENV === "test") {
    baseUrl = "https://tx.alang.run/api";
    kuwoHttps = "https://tx.alang.run/kuwo";
} else if (process.env.NODE_ENV === "production") {
    baseUrl = "https://tx.alang.run/api";
    kuwoHttps = "https://tx.alang.run/kuwo";
}

let isProduction = false;

if (process.env.NODE_ENV === "production") {
    isProduction = true;
}

const jsonServerUrl = "http://skyxsxpc.top:9080/interface/json/"

export {
    baseUrl,
    isProduction,
    kuwoHttps,
    jsonServerUrl
}
